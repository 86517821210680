import Slider from 'react-slick';
import {ReservationsFromLg12} from '../components/ReservationsFrom';
import Layout from '../layout/Layout';
import {Link} from 'react-router-dom';
import {testimonialSliderOne} from '../sliderProps';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {
    heroShap1,
    heroShap2,
    line1,
    downArrow,
    hero1,
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    thumb1,
    thumb2,
    thumb3,
    thumb4,
    element1,
    image1,
    image2,
    menuSingle1,
    chef1,
    chef2,
    chef3,
    chef4,
    chef5,
    chef6,
    video1,
    contact1,
    testThumb1,
    testThumb2,
    testThumb3,
    authorThumb1,
    insta1,
    insta2,
    insta3,
    insta4,
    insta5,
    insta6,
    insta7,
    insta8,
    insta9,
    insta10,
    insta11,
    insta12,
    catBG1,
    dotBg,
    hourBg1
} from '../assets/images';

const Index = () => {
    return (
        <Layout header={1}
            footer={1}>
            {/*=== Start Banner Section ===*/}
            <section className="banner-one p-r z-1">
                <div className="shape shape-one">
                    <span>
                        <img src={heroShap1}
                            alt="shape"/>
                    </span>
                </div>
                <div className="shape shape-two">
                    <span>
                        <img src={heroShap2}
                            alt="shape"/>
                    </span>
                </div>
                <div className="shape line-shape-one">
                    <span>
                        <img src={line1}
                            alt="shape"/>
                    </span>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1 order-2">
                            {/*=== Hero Content ===*/}
                            <div className="hero-content mb-40 pr-lg-40">
                                <h1 className="wow fadeInUp" data-wow-delay=".5s">
                                    Double Bacon Cheeseburger
                                </h1>
                                <p className="wow fadeInDown" data-wow-delay=".7s">
                                    Enjoy all combinations of toppings, vegetables, sauces,
                                                                                                                              spices, fresh buns, crispy bacon, juicy beef, and melted
                                                                                                                              cheeses of any variety. It’s a life changing experience!
                                </p>
                                <div className="hero-button wow fadeInUp" data-wow-delay=".9s">
                                    <span>
                                        <img src={downArrow}
                                            alt=""/>
                                    </span>
                                    <Link legacyBehavior href="/menu-seafood">
                                        <a className="main-btn btn-red">
                                            order now
                                            <i className="far fa-arrow-right"/>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-1">
                            {/*=== Hero Image ===*/}
                            <div className="hero-image-box mb-40 wow fadeInRight" data-wow-delay=".7s">
                                <img src={hero1}
                                    alt="Hero Image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Banner Section ===*/}
            {/*=== Start Category Section ===*/}
            <section className="category-bg-section pt-70 pb-80 p-r z-1"
                style={
                    {
                        backgroundImage: 'url(' + catBG1 + ')'
                    }
            }>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-10">
                            {/*=== Section Title ===*/}
                            <div className="section-title text-center text-white mb-50 wow fadeInDown">
                                <span className="sub-title">Our Popular Food</span>
                                <h2>Best Category Foods Menu</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {/*=== Single Category Item ===*/}
                            <div className="single-category-item mb-30 wow fadeInUp">
                                <div className="icon">
                                    <img src={icon1}
                                        alt="thumb icon"/>
                                </div>
                                <div className="content d-flex">
                                    <h3 className="title">
                                        <Link legacyBehavior href="/menu-seafood">
                                            <a>Breakfast Meals</a>
                                        </Link>
                                    </h3>
                                    <Link legacyBehavior href="/menu-seafood">
                                        <a className="icon-btn">
                                            <i className="far fa-arrow-right"/>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {/*=== Single Category Item ===*/}
                            <div className="single-category-item mb-30 wow fadeInUp">
                                <div className="icon">
                                    <img src={icon2}
                                        alt="thumb icon"/>
                                </div>
                                <div className="content d-flex">
                                    <h3 className="title">
                                        <Link legacyBehavior href="/menu-seafood">
                                            <a>Burger</a>
                                        </Link>
                                    </h3>
                                    <Link legacyBehavior href="/menu-seafood">
                                        <a className="icon-btn">
                                            <i className="far fa-arrow-right"/>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {/*=== Single Category Item ===*/}
                            <div className="single-category-item mb-30 wow fadeInUp">
                                <div className="icon">
                                    <img src={icon3}
                                        alt="thumb icon"/>
                                </div>
                                <div className="content d-flex">
                                    <h3 className="title">
                                        <Link legacyBehavior href="menu-seafood">
                                            Soups and Salads
                                        </Link>
                                    </h3>
                                    <Link legacyBehavior href="/menu-seafood">
                                        <a className="icon-btn">
                                            <i className="far fa-arrow-right"/>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {/*=== Single Category Item ===*/}
                            <div className="single-category-item mb-30 wow fadeInUp">
                                <div className="icon">
                                    <img src={icon4}
                                        alt="thumb icon"/>
                                </div>
                                <div className="content d-flex">
                                    <h3 className="title">
                                        <Link legacyBehavior href="menu-seafood">
                                            Family Packs
                                        </Link>
                                    </h3>
                                    <Link legacyBehavior href="/menu-seafood">
                                        <a className="icon-btn">
                                            <i className="far fa-arrow-right"/>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {/*=== Single Category Item ===*/}
                            <div className="single-category-item mb-30 wow fadeInUp">
                                <div className="icon">
                                    <img src={icon5}
                                        alt="thumb icon"/>
                                </div>
                                <div className="content d-flex">
                                    <h3 className="title">
                                        <Link legacyBehavior href="menu-seafood">
                                            Desserts
                                        </Link>
                                    </h3>
                                    <Link legacyBehavior href="/menu-seafood">
                                        <a className="icon-btn">
                                            <i className="far fa-arrow-right"/>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {/*=== Single Category Item ===*/}
                            <div className="single-category-item mb-30 wow fadeInUp">
                                <div className="icon">
                                    <img src={icon6}
                                        alt="thumb icon"/>
                                </div>
                                <div className="content d-flex">
                                    <h3 className="title">
                                        <Link legacyBehavior href="menu-seafood">
                                            Subs and Sandwitches
                                        </Link>
                                    </h3>
                                    <Link legacyBehavior href="/menu-seafood">
                                        <a className="icon-btn">
                                            <i className="far fa-arrow-right"/>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            {/*=== Quote Box ===*/}
                            <div className="quote-box text-center text-white wow fadeInUp">
                                <p>
                                    Need any Special Food or Any Birthday Cakes?{' '}
                                    <Link legacyBehavior href="/menu-fastfood">
                                        <a>Order Now</a>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Category Section ===*/}
            {/*=== Start About Section ===*/}
            <section className="about-section pt-130 pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            {/*=== About Content Box ===*/}
                            <div className="about-content-box content-box-gap mb-40 wow fadeInLeft">
                                <div className="section-title section-title-left mb-30">
                                    <span className="sub-title">About Kitchen</span>
                                    <h2>
                                        The Best Tasty &amp; Yummy Food Based on American Cusine
                                    </h2>
                                </div>
                                <p>
                                    Try our variety of Gourmet Burgers, Hamburgers and Pulled Pork
                                                                                                                              with Fresh Buns. Ask about Family packs that feed up to 16
                                                                                                                              people
                                </p>
                                <Link legacyBehavior href="/menu-seafood">
                                    <a className="main-btn btn-red">
                                        learn more
                                        <i className="far fa-arrow-right"/>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            {/*=== About Content Box ===*/}
                            <div className="about-image-gallery ml-lg-40">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={image1}
                                            className="mb-40 wow fadeInUp"
                                            alt="About Image"/>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={image2}
                                            className="mb-40 wow fadeInDown"
                                            alt="About Image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End About Section ===*/}
            {/*=== Start Menu Section ===*/}
            <section className="menu-section pb-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-10">
                            {/*=== Section Title ===*/}
                            <div className="section-title text-center mb-50 wow fadeInDown">
                                <span className="sub-title">Best food menu</span>
                                <h2>Choose Your Best Menus</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            {/*=== Menu Image Box ===*/}
                            <div className="menu-image-box mb-50 pl-lg-30 wow fadeInLeft">
                                <img src={menuSingle1}
                                    alt="Menu Image"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/*=== Single Content Box ===*/}
                            <div className="menu-content-box mb-50">
                                {/*=== Single Menu Item ===*/}
                                <div className="single-menu-item mb-30 wow fadeInUp">
                                    <div className="thumb">
                                        <img src={thumb1}
                                            alt=""/>
                                    </div>
                                    <div className="text">
                                        <h3 className="item-title-price">
                                            <Link legacyBehavior href="/menu-fastfood">
                                                <a className="item-title">Hamburger</a>
                                            </Link>
                                            <span className="dot-border"/>
                                            <span className="price">$5.9</span>
                                        </h3>
                                        <p>Roasted langoustine, consommé</p>
                                        <ul className="ratings">
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <span>
                                                    <a href="#">(5k Reviews)</a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/*=== Single Menu Item ===*/}
                                <div className="single-menu-item mb-30 wow fadeInUp">
                                    <div className="thumb">
                                        <img src={thumb2}
                                            alt=""/>
                                    </div>
                                    <div className="text">
                                        <h3 className="item-title-price">
                                            <Link legacyBehavior href="/menu-fastfood">
                                                <a className="item-title">Pulled Pork Sandwich</a>
                                            </Link>
                                            <span className="dot-border"/>
                                            <span className="price">$5.9</span>
                                        </h3>
                                        <p>Roasted langoustine, consommé</p>
                                        <ul className="ratings">
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <span>
                                                    <a href="#">(5k Reviews)</a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/*=== Single Menu Item ===*/}
                                <div className="single-menu-item mb-30 wow fadeInUp">
                                    <div className="thumb">
                                        <img src={thumb3}
                                            alt=""/>
                                    </div>
                                    <div className="text">
                                        <h3 className="item-title-price">
                                            <Link legacyBehavior href="/menu-fastfood">
                                                <a className="item-title">
                                                    Chicken Wing Plate 5 pieces
                                                </a>
                                            </Link>
                                            <span className="dot-border"/>
                                            <span className="price">$11.8</span>
                                        </h3>
                                        <p>Roasted langoustine, consommé</p>
                                        <ul className="ratings">
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <span>
                                                    <a href="#">(5k Reviews)</a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/*=== Single Menu Item ===*/}
                                <div className="single-menu-item mb-30 wow fadeInUp">
                                    <div className="thumb">
                                        <img src={thumb4}
                                            alt=""/>
                                    </div>
                                    <div className="text">
                                        <h3 className="item-title-price">
                                            <Link legacyBehavior href="/menu-fastfood">
                                                <a className="item-title">
                                                    {' '}
                                                    2 Eggs and Country Ham Breakfast
                                                </a>
                                            </Link>
                                            <span className="dot-border"/>
                                            <span className="price">$12.98</span>
                                        </h3>
                                        <p>Roasted langoustine, consommé</p>
                                        <ul className="ratings">
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fas fa-star"/>
                                            </li>
                                            <li>
                                                <span>
                                                    <a href="#">(5k Reviews)</a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Menu Section ===*/}
            {/*=== Start Chefs Section ===*/}
            <section className="chefs-bg-section light-red-bg pt-130 pb-100 bg_cover p-r z-1">
                <div className="dot-bg bg_cover"
                    style={
                        {
                            backgroundImage: 'url(' + dotBg + ')'
                        }
                    }/>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-12">
                            {/*=== Chef Content Box ===*/}
                            <div className="chef-content-box content-box-gap mb-30 pr-lg-30 wow fadeInLeft">
                                <div className="section-title section-title-left mb-30">
                                    <span className="sub-title">Best food menu</span>
                                    <h2>Meet Our Exclusive &amp; Master Chefs</h2>
                                </div>
                                <ul className="check-style-one">
                                    <li>25 Years of Experience in Restaurant Services in USA</li>
                                    <li>
                                        Any Kind Of Food Made For Customer and So Much Yamee $ Testy
                                    </li>
                                </ul>
                                <p>Work with us by filling up this form
                                </p>
                                <Link legacyBehavior href="/chefs">
                                    <a className="main-btn filled-btn">
                                        become a chef
                                        <i className="far fa-arrow-right"/>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12">
                            <div className="row">
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    {/*=== Single Team Item ===*/}
                                    <div className="single-team-item mb-30 wow fadeInUp">
                                        <div className="chef-img">
                                            <img src={chef1}
                                                alt="Chef Image"/>
                                            <div className="chef-overlay"/>
                                            <div className="hover-content">
                                                <h3 className="title">Jimmie K. Cryer</h3>
                                                <p className="position">Senior Chef</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    {/*=== Single Team Item ===*/}
                                    <div className="single-team-item mb-30 wow fadeInUp">
                                        <div className="chef-img">
                                            <img src={chef2}
                                                alt="Chef Image"/>
                                            <div className="chef-overlay"/>
                                            <div className="hover-content">
                                                <h3 className="title">Jimmie K. Cryer</h3>
                                                <p className="position">Senior Chef</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    {/*=== Single Team Item ===*/}
                                    <div className="single-team-item mb-30 wow fadeInUp">
                                        <div className="chef-img">
                                            <img src={chef3}
                                                alt="Chef Image"/>
                                            <div className="chef-overlay"/>
                                            <div className="hover-content">
                                                <h3 className="title">Jimmie K. Cryer</h3>
                                                <p className="position">Senior Chef</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    {/*=== Single Team Item ===*/}
                                    <div className="single-team-item mb-30 wow fadeInUp">
                                        <div className="chef-img">
                                            <img src={chef4}
                                                alt="Chef Image"/>
                                            <div className="chef-overlay"/>
                                            <div className="hover-content">
                                                <h3 className="title">Jimmie K. Cryer</h3>
                                                <p className="position">Senior Chef</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    {/*=== Single Team Item ===*/}
                                    <div className="single-team-item mb-30 wow fadeInUp">
                                        <div className="chef-img">
                                            <img src={chef5}
                                                alt="Chef Image"/>
                                            <div className="chef-overlay"/>
                                            <div className="hover-content">
                                                <h3 className="title">Jimmie K. Cryer</h3>
                                                <p className="position">Senior Chef</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-sm-12">
                                    {/*=== Single Team Item ===*/}
                                    <div className="single-team-item mb-30 wow fadeInUp">
                                        <div className="chef-img">
                                            <img src={chef6}
                                                alt="Chef Image"/>
                                            <div className="chef-overlay"/>
                                            <div className="hover-content">
                                                <h3 className="title">Jimmie K. Cryer</h3>
                                                <p className="position">Senior Chef</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {' '}
            {/*=== End Chefs Section ===*/}
            {/*=== Start Video Section ===*/}
            <section className="video-line-section pt-130 pb-80 p-r z-1">
                <div className="line-shape line-shape-one">
                    <span>
                        <img src={line1}
                            alt="Shape"/>
                    </span>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            {/*=== Video Image Box ===*/}
                            <div className="video-one_image-box mb-50 p-r z-1 wow fadeInLeft">
                                <img src={video1}
                                    alt="Video Image"/>
                                <div className="image-overlay"/>
                                <div className="play-content-box">
                                    <a href="https://www.youtube.com/watch?v=t8k71QcArnk" className="video-popup">
                                        <i className="fas fa-play"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            {/*=== Video Content Box ===*/}
                            <div className="video-content-box content-box-gap pl-lg-40 mb-50 wow fadeInRight">
                                <div className="section-title section-title-left">
                                    <span className="sub-title">Watch Videos</span>
                                    <h2>What we offer for our Customers
                                    </h2>
                                </div>
                                <p>
                                    We’ve added plate lunches & dinners, all-day breakfast, and
                                                                                                                              we’ve recently revamped our BBQ menu. All of our meats are
                                                                                                                              smoked in-house and paired with our signature slap sauce.
                                </p>
                                <ul className="check-style-one mb-30">
                                    <li>Best Way to Serve Our Foods</li>
                                    <li>Low Cost &amp; Onlie Orders</li>
                                </ul>
                                <Link legacyBehavior href="/about">
                                    <a className="main-btn btn-black">
                                        watch more
                                        <i className="far fa-arrow-right"/>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Video Section ===*/}
            {/*=== Start Working Section ===*/}
            <section className="working-bg-section pt-130 pb-110 light-red-bg p-r z-1">
                <div className="dot-bg bg_cover"
                    style={
                        {
                            backgroundImage: 'url(' + dotBg + ')'
                        }
                    }/>
                <div className="hours-bg bg_cover"
                    style={
                        {
                            backgroundImage: 'url(' + hourBg1 + ')'
                        }
                    }/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            {/*=== Working Content Box ===*/}
                            <div className="working-content-box wow fadeInLeft">
                                <div className="section-title section-title-left mb-50">
                                    <span className="sub-title">Working hour</span>
                                    <h2>
                                        We are open 24 hours, 7 days a week at 405 N Riverside Dr.
                                                                                                                                            Let’s eat!
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Working Section ===*/}
            {/*=== Start Reservation Section ===*/}
            <section className="reservation-line-section p-r z-1 pt-130 pb-80">
                <div className="line-shape line-shape-one">
                    <span>
                        <img src={line1}
                            alt="Shape"/>
                    </span>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            {/*=== Reservation Image Box ===*/}
                            <div className="reservation-image-box wow fadeInLeft mb-50 mb-20">
                                <img src={contact1}
                                    alt="Reservation Image"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/*=== Reservation Content Box ===*/}
                            <div className="reservation-content-box content-box-gap wow fadeInRight mb-50 pl-lg-70">
                                <div className="section-title section-title-left mb-20">
                                    <span className="sub-title">Booking table</span>
                                    <h2>Make A Reservations</h2>
                                </div>
                                <p>
                                    Sit with family and enjoy our family packs that feed from 4 to
                                                                                                                              16 people
                                </p>
                                {/*=== Reservation Form ===*/}
                                <div className="reservation-style-one">
                                    <ReservationsFromLg12/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Reservation Section ===*/}
            {/*=== Start Testimonial Section ===*/}
            <section className="testimonial-section-one dark-black-bg p-r z-1 pt-120 pb-120">
                <div className="shape line-shape-one">
                    <span>
                        <img src={line1}
                            alt="Shape"/>
                    </span>
                </div>
                <div className="shape shape-one">
                    <span>
                        <img src={element1}
                            alt=""/>
                    </span>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-10">
                            {/*=== Section Title ===*/}
                            <div className="section-title text-center text-white mb-50 wow fadeInDown">
                                <span className="sub-title">Foods reviews</span>
                                <h2>Global Customer Feedback</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                {/*=== Testimonial Image Box ===*/}
                                <div className="testimonial-one_image-box p-r wow fadeInLeft">
                                    <img src={testThumb1}
                                        className="testimonial-img-one"
                                        alt="Testimonial Image"/>
                                    <img src={testThumb2}
                                        className="testimonial-img-two"
                                        alt="Testimonial Image"/>
                                    <img src={testThumb3}
                                        className="testimonial-img-three"
                                        alt="Testimonial Image"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                {/*=== Testimonial Wrapper ===*/}
                                <div className="testimonial-wrapper-one wow fadeInRight mr-lg-100">
                                    {/*=== Testimonial Slider ===*/}
                                    <Slider {...testimonialSliderOne} className="testimonial-slider-one">
                                        {/*=== Single Testimonial ===*/}
                                        <div className="single-testimonial-one">
                                            <div className="testimonial-inner-content">
                                                <p>
                                                    I went yesterday for the first time and I am back
                                                                                                                                                                                      again today. couldn't stop thinking about their
                                                                                                                                                                                      burgers! also tried their potato soup and it was
                                                                                                                                                                                      honestly one of the best I have ever had!! would
                                                                                                                                                                                      recommend 100%! And the prices? Amazing!
                                                </p>
                                                <div className="author-quote-box d-flex justify-content-between">
                                                    <div className="author-title-thumb d-flex">
                                                        <div className="author-thumb">
                                                            <img src={authorThumb1}
                                                                alt="Author Thumb"/>
                                                        </div>
                                                        <div className="author-title">
                                                            <h3 className="title">Laura Haubrich</h3>
                                                            <p className="position">Customer</p>
                                                        </div>
                                                    </div>
                                                    <div className="quote">
                                                        <i className="flaticon-right-quote"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*=== Single Testimonial ===*/}
                                        <div className="single-testimonial-one">
                                            <div className="testimonial-inner-content">
                                                <p>
                                                    Our new favorite! Shout out to Bree who took
                                                                                                                                                                                      exceptional care of us and ensured she was always 1-2
                                                                                                                                                                                      steps ahead of our needs! Best burgers we have found
                                                                                                                                                                                      in town. I love their home fries and their Strawberry
                                                                                                                                                                                      Milkshake is excellent. We ate very well and will
                                                                                                                                                                                      definitely return. You’ve won our hearts and tummies.
                                                                                                                                                                                      Child-friendly · Indoor Dining - Large portions - Cosy
                                                                                                                                                                                      atmosphere - Great dessert - Daily specials -
                                                                                                                                                                                      Late-night dining - Casual - Comfort food - Great food
                                                                                                                                                                                      - Large menu - Cheap eats
                                                </p>
                                                <div className="author-quote-box d-flex justify-content-between">
                                                    <div className="author-title-thumb d-flex">
                                                        <div className="author-thumb">
                                                            <img src={authorThumb1}
                                                                alt="Author Thumb"/>
                                                        </div>
                                                        <div className="author-title">
                                                            <h3 className="title">Tom Canterbury</h3>
                                                            <p className="position">Customer</p>
                                                        </div>
                                                    </div>
                                                    <div className="quote">
                                                        <i className="flaticon-right-quote"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Testimonial Section ===*/}
            {/*=== Start Instagram Section ===*/}
            <section className="instagram-gallery pt-130 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta1}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-1.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta2}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-2.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta3}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-3.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta4}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-4.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta5}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-5.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta6}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-6.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta7}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-7.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta8}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-8.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta9}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-9.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta10}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-10.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta11}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-11.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            {/*=== Instagram Box ===*/}
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta12}
                                        alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-12.jpg" className="img-popup insta-icon">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=== End Instagram Section ===*/} </Layout>
    );
};
export default Index;
