
import PageBanner from "../components/PageBanner";
import Partners from "../components/Partners";
import QichenCounter from "../components/QichenCounter";
import TestimonialSliderThree from "../components/slider/TestimonialSliderThree";
import Layout from "../layout/Layout";
import {shap1,shap2,image7,image8,image9,chef7,chef8,chef9,chef10,line1,video1} from "../assets/images"
import { Link } from "react-router-dom";

const About = () => {
  return (
    <Layout>
      <PageBanner pageName={"About Us"} />
      <section className="about-shape-section pt-130 pb-80 p-r z-1">
        <div className="shape shape-one">
          <span>
            <img src={shap1} alt="shape" />
          </span>
        </div>
        <div className="shape shape-two">
          <span>
            <img src={shap2} alt="shape" />
          </span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-three_image-box text-lg-end mb-20">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <img
                      src={image7}
                      className="about-img-one mb-30 wow fadeInUp"
                      alt="About Image"
                    />
                    <img
                      src={image8}
                      className="about-img-two mb-30 wow fadeInDown"
                      alt="About Image"
                    />
                  </div>
                  <div className="col-md-6">
                    <img
                      src={image9}
                      className="about-img-three mb-30 wow fadeInRight"
                      alt="About Image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-three_content-box content-box-gap pl-lg-45 mb-50">
                <div className="section-title section-title-left mb-30 wow fadeInUp">
                  <span className="sub-title">ABOUT Kellys big burger</span>
                  {/* <h2>The Cleanest, Most Delicious Vegan Cookie Dough Ever.</h2> */}
                </div>
                <p className="wow fadeInDown">
                Established in 1964, Kelly’s Big Burger has been serving delicious fresh hamburgers to the Clarksville community for decades and has become a staple in the town’s history.
Since then, we’ve added plate lunches & dinners, all-day breakfast, and we’ve recently revamped our BBQ menu. All of our meats are smoked in-house and paired with our signature slap sauce.
We are open 24 hours, 7 days a week at 405 N Riverside Dr.
Let’s eat!
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="fancy-icon-box mb-50 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-food-serving" />
                      </div>
                      <div className="text">
                        <h5>532+ Popular Testy Foods Menu</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fancy-icon-box mb-50 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-discuss" />
                      </div>
                      <div className="text">
                        <h5>6534+ Satisfied Our Global Customers</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fancy-icon-box mb-50 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-certificate" />
                      </div>
                      <div className="text">
                        <h5>We’ve 25+ Years Of Experience</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fancy-icon-box mb-50 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-smartphone" />
                      </div>
                      <div className="text">
                        <h5>432+ Foods Iteams Online Orders</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="about-button wow fadeInUp">
                  <Link legacyBehavior href="/about">
                    <a className="main-btn btn-red">
                      learn more
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                  <a href="tel:000(123)45689" className="call-button">
                    <div className="icon">
                      <i className="fas fa-headset" />
                    </div>
                    <div className="text">
                      <span>Call For Order</span>
                      <h5>000 (123) 456 89</h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=== End About Section ===*/}
      {/*=== Start Fact Section ===*/}
      <section className="fact-section dark-red-bg pt-75 pb-35">
        <div className="container">
          <QichenCounter />
        </div>
      </section>
      {/*=== End Fact Section ===*/}
      {/*=== Start Why_choose Section ===*/}
      <section className="why-choose-us pt-130 pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-12">
              <div className="choose-content-box content-box-gap mb-30 wow fadeInLeft">
                <div className="section-title section-title-left mb-20">
                  <span className="sub-title">Why Choos us</span>
                  <h2>
                    We’re Awards Winning Restaurant. 25+ Years Of Experience In
                    Restaurant Services
                  </h2>
                </div>
                <p>
                  Sed ut perspiciatis unde omnis iste natus sit voluptatem
                  accusantium doloremque laudantium totam rem aperiam eaque ipsa
                  quae ab illo inventore veritatis
                </p>
                <ul className="check-style-one mb-35">
                  <li>Best Way to Serve Our Foods</li>
                  <li>Low Cost &amp; Onlie Orders</li>
                </ul>
                <Link legacyBehavior href="/about">
                  <a className="main-btn btn-red">
                    learn more
                    <i className="far fa-arrow-right" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="choose-item-list">
                <div className="row">
                  <div className="col-md-6">
                    <div className="fancy-features-item mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-pizza-slice-1" />
                      </div>
                      <div className="text">
                        <h3 className="title">Fastfoods</h3>
                        <p>Sit amet consecte adisce epsum nisi commos</p>
                        <Link legacyBehavior href="/about">
                          <a className="btn-link">Read more</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fancy-features-item mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-chef-1" />
                      </div>
                      <div className="text">
                        <h3 className="title">Experience Chefs</h3>
                        <p>Sit amet consecte adisce epsum nisi commos</p>
                        <Link legacyBehavior href="/about">
                          <a className="btn-link">Read more</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fancy-features-item mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-delivery-man" />
                      </div>
                      <div className="text">
                        <h3 className="title">Online Delivery</h3>
                        <p>Sit amet consecte adisce epsum nisi commos</p>
                        <Link legacyBehavior href="/about">
                          <a className="btn-link">Read more</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fancy-features-item mb-30 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-food-serving" />
                      </div>
                      <div className="text">
                        <h3 className="title">Fine Dining</h3>
                        <p>Sit amet consecte adisce epsum nisi commos</p>
                        <Link legacyBehavior href="/about">
                          <a className="btn-link">Read more</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=== End Why_choose Section ===*/}
      {/*=== Start Team Section ===*/}
      <section className="team-section light-red-bg p-r z-1 pt-120 pb-100">
        <div
          className="dot-bg bg_cover"
          style={{ backgroundImage: "url(assets/images/bg/dot-bg.png)" }}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-10">
              <div className="section-title text-center mb-50 wow fadeInDown">
                <span className="sub-title">Best food menu</span>
                <h2>Meet Our Experience &amp; Master Chefs</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="single-team-item mb-30 wow fadeInUp">
                <div className="chef-img">
                  <img src={chef7} alt="Chef Image" />
                  <div className="chef-overlay" />
                  <div className="hover-content">
                    <h3 className="title">Jimmie K. Cryer</h3>
                    <p className="position">Senior Chef</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="single-team-item mb-30 wow fadeInUp">
                <div className="chef-img">
                  <img src={chef8} alt="Chef Image" />
                  <div className="chef-overlay" />
                  <div className="hover-content">
                    <h3 className="title">Jimmie K. Cryer</h3>
                    <p className="position">Senior Chef</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="single-team-item mb-30 wow fadeInUp">
                <div className="chef-img">
                  <img src={chef9} alt="Chef Image" />
                  <div className="chef-overlay" />
                  <div className="hover-content">
                    <h3 className="title">Jimmie K. Cryer</h3>
                    <p className="position">Senior Chef</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="single-team-item mb-30 wow fadeInUp">
                <div className="chef-img">
                  <img src={chef10} alt="Chef Image" />
                  <div className="chef-overlay" />
                  <div className="hover-content">
                    <h3 className="title">Jimmie K. Cryer</h3>
                    <p className="position">Senior Chef</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="team-button mt-30 text-center wow fadeInUp">
                <Link legacyBehavior href="/chefs">
                  <a className="main-btn btn-red">
                    become a chef
                    <i className="far fa-arrow-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=== End Team Section ===*/}
      {/*=== Start Video Section ===*/}
      <section className="video-line-section pt-130 pb-80 p-r z-1">
        <div className="line-shape line-shape-one">
          <span>
            <img src={line1} alt="Shape" />
          </span>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              {/*=== Video Image Box ===*/}
              <div className="video-one_image-box mb-50 p-r z-1 wow fadeInLeft">
                <img
                  src={video1}
                  alt="Video Image"
                />
                <div className="image-overlay" />
                <div className="play-content-box">
                  <a
                    href="https://www.youtube.com/watch?v=t8k71QcArnk"
                    className="video-popup"
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              {/*=== Video Content Box ===*/}
              <div className="video-content-box content-box-gap pl-lg-40 mb-50 wow fadeInRight">
                <div className="section-title section-title-left">
                  <span className="sub-title">Watch Videos</span>
                  <h2>How Can We Made Foods For Customers</h2>
                </div>
                <p>
                  Sit amet consectetur adipiscing elitsue risus mauris quam
                  adipiscing phasellus aene ante orcirat scelerisque enim ut
                  nulla
                </p>
                <ul className="check-style-one mb-30">
                  <li>Best Way to Serve Our Foods</li>
                  <li>Low Cost &amp; Onlie Orders</li>
                </ul>
                <Link legacyBehavior href="/about">
                  <a className="main-btn btn-black">
                    watch more
                    <i className="far fa-arrow-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=== End Video Section ===*/}
      {/*=== Start Team Section ===*/}
      <section className="testimonial-section-three pb-130">
        <div className="container">
          <TestimonialSliderThree />
        </div>
      </section>
      {/*=== End Team Section ===*/}
      {/*====== Start Partners Section ======*/}
      <Partners />
    </Layout>
  );
};
export default About;
