import React from 'react';

import PageBanner from "../components/PageBanner";
import Partners from "../components/Partners";
import ReservationsFrom from "../components/ReservationsFrom";
import {Link, useLocation} from "react-router-dom";
import Layout from "../layout/Layout";
import {
    menuSingle1,
    menuSingle2,
    menuThumb1,
    menuThumb2,
    menuThumb3,
    menuThumb4,
    menuThumb5,
    menuThumb6,
    menuThumb7,
    menuThumb8
} from '../assets/images'
import { useMenuState } from "../contexts/MenuContext";
const Menu = () => {
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const category = queryParams.get('category');
  debugger
const[allItems,setAllItems] =React.useState()
  const {items} =useMenuState();
  const setItemssBasedOnSubCategories = React.useCallback(() => {
    const pluckedCategories =
      items &&
      items.filter((item)=>item.Category==category)
        .map(({ SubCategory }) => SubCategory)
        .filter((id, index, ids) => ids.indexOf(id) === index);

    const itemsBasedCategories = [];
    
    items &&
      items.filter((item)=>item.Category==category).forEach((item) =>
        pluckedCategories.includes(item.SubCategory) &&
        !!itemsBasedCategories[pluckedCategories.indexOf(item.SubCategory)]
          ? itemsBasedCategories[pluckedCategories.indexOf(item.SubCategory)][
              item.SubCategory
            ].push(item)
          : itemsBasedCategories.push({ [item.SubCategory]: [item] })
      );
    setAllItems( itemsBasedCategories);
    
  }, [
    items,category
  ]);

  
  React.useEffect(() => {
    setItemssBasedOnSubCategories();
    
  }, [setItemssBasedOnSubCategories]);
    return (
        <Layout>
            <PageBanner pageName={"Fastfood"}
                title="Menu"/> {/*====== End Breadcrumb Section ======*/}
            {/*=== Start Menu Section ===*/}
            <section className="menu-section pt-125 pb-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-10">
                            <div className="section-title text-center mb-50 wow fadeInUp">
                                <span className="sub-title">Best food menu</span>
                                <h2>Choose Your Best Menus</h2>
                            </div>
                        </div>
                    </div>
                    {allItems && (
              allItems?.length > 0 && (
              
                allItems.map((n, index) => {
                  
                    let subcatName = Object.keys(n);
                    let selecteditems = Object.values(n)[0];
                    return (
                    <div className="row align-items-center" key={index}>
                        <div className="col-lg-6">
                            <div className="menu-img-box mb-50 pl-lg-30 wow fadeInLeft">
                                <img src={menuSingle1} alt="Menu Image"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                          <span className="sub-title">{subcatName}</span>
                            <div className="menu-content-box mb-50 wow fadeInUp">
                            {selecteditems.map((item,index)=>
                                <div className="single-menu-item mb-30" key={index}>
                                    <div className="thumb">
                                        <img src={menuThumb1} alt=""/>
                                    </div>
                                    <div className="text">
                                        <h3 className="item-title-price">
                                            <Link legacyBehavior href="/menu-fastfood">
                                                <a className="item-title">{item.Menu}</a>
                                            </Link>
                                            <span className="dot-border"/>
                                            <span className="price">{item.Price}</span>
                                        </h3>
                                        <p>{item.SubText}</p>
                                    
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>)})))}
                </div>
            </section>
            {/*=== End Menu Section ===*/}
            {/* === Start Reservation Section ===*/}
            {/* <section className="reservation-section light-red-bg pt-100 pb-50">
                <div className="container">
                    <div className="reservation-wrapper-one p-r z-1">
                        <div className="reservation-after-img wow fadeInRight">
                            <img src={contact3} alt="contact image"/>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="reservation-two_image-box ml-minus-lg-60 mb-50 wow fadeInLeft">
                                    <img src={contact2} alt="Contact Image"/>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="reservation-content-box mb-50 wow fadeInUp">
                                    <div className="section-title section-title-left mb-35">
                                        <span className="sub-title">Booking table</span>
                                        <h2>Make A Reservations</h2>
                                    </div>
                                    <div className="reservation-style-three">
                                        <ReservationsFrom btnLeft/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/*=== End Reservation Section ===*/}
            {/*=== Start Instagram Section ===*/}
            {/* <section className="instagram-gallery pt-130 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta1} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-1.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta2} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-2.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta3} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-3.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta4} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-4.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta5} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-5.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta6} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-6.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta7} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-7.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta8} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-8.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta9} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-9.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta10} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-10.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInUp">
                                <div className="instagram-img">
                                    <img src={insta11} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-11.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <div className="instagram-box mb-30 wow fadeInDown">
                                <div className="instagram-img">
                                    <img src={insta12} alt="Instagram Image"/>
                                    <div className="insta-overlay"/>
                                    <a href="assets/images/instagram/insta-12.jpg" className="img-popup insta-icon">
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/*=== End Instagram Section ===*/}
            {/*====== Start Partners Section ====== */}
            <Partners/>
        </Layout>
    );
};
export default Menu;
