import React from 'react';


export enum MenuActionTypes {
  LOADING = 'loading',
  UNLOADING = 'unloading',
  SUCCESS = 'success',
}

interface MenuState {
  items?: [];
  categories?: [];
  isLoading: boolean;
}

type MenuDispatch = (action: MenuAction) => void;
type MenuAction =
  | { type: MenuActionTypes.LOADING }
  | { type: MenuActionTypes.UNLOADING }
  | { type: MenuActionTypes.SUCCESS; payload: any };

const MenuStateContext = React.createContext<MenuState | undefined>(undefined);
const MenuDispatchContext =
  React.createContext<MenuDispatch | undefined>(undefined);

function MenuReducer(state: MenuState, action: MenuAction): MenuState {
  switch (action.type) {
    case MenuActionTypes.LOADING: {
      return { isLoading: true };
    }
    case MenuActionTypes.UNLOADING: {
      return { isLoading: false };
    }
    case MenuActionTypes.SUCCESS: {
      return {
        isLoading: false,
        items: action.payload.items,
        categories: action.payload.categories,
      };
    }
    default: {
      throw new Error('Invalid action type');
    }
  }
}

export const MenuProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(MenuReducer, { isLoading: true });

  
  const getList = React.useCallback(async () => {
    dispatch({ type: MenuActionTypes.LOADING });
    let alldata;
    let catUnique
    const url:any=process.env.REACT_APP_MENU_SHEET;
    await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        alldata=data
       const allCat= data.map((item:any)=>item.Category)
         catUnique=allCat.filter((item:any,
          index:any) => allCat.indexOf(item) === index)
      })
      .catch((error) => {
        console.error(error);
      })


    dispatch({
      type: MenuActionTypes.SUCCESS,
      payload: {
        items: alldata,
        categories: catUnique
      },
    });
  }, []);

  React.useEffect(() => {
    getList();
  }, [getList]);

  return (
    <MenuStateContext.Provider value={state}>
      <MenuDispatchContext.Provider value={dispatch}>
        {children}
      </MenuDispatchContext.Provider>
    </MenuStateContext.Provider>
  );
};

export const useMenuState = () => {
  const menuStateContext = React.useContext(MenuStateContext);
  if (menuStateContext === undefined) {
    throw new Error('useMenuState must be used within a MenuProvider');
  }
  return menuStateContext;
};

export const useMenuDispatch = () => {
  const menuDispatchContext = React.useContext(MenuDispatchContext);
  if (menuDispatchContext === undefined) {
    throw new Error('useMenuDispatch must be used within a MenuProvider');
  }
  return menuDispatchContext;
};
