import Slider from "react-slick";
import {partnerSliderOne} from "../sliderProps";
import {
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6
} from "../assets/images"

const Partners = () => {
    return (
        <section className="partners-section light-red-bg pt-60 pb-60">
            <div className="container">
                {/*=== Partners Slider ===*/}
                <Slider {...partnerSliderOne} className="partner-slider-one wow fadeInDown">
                    <div className="single-partner-item">
                        <div className="partner-img">
                            <a href="#">
                                <img src={partner1}
                                    alt="Partner Image"/>
                            </a>
                        </div>
                    </div>
                    <div className="single-partner-item">
                        <div className="partner-img">
                            <a href="#">
                                <img src={partner2}
                                    alt="Partner Image"/>
                            </a>
                        </div>
                    </div>
                    <div className="single-partner-item">
                        <div className="partner-img">
                            <a href="#">
                                <img src={partner3}
                                    alt="Partner Image"/>
                            </a>
                        </div>
                    </div>
                    <div className="single-partner-item">
                        <div className="partner-img">
                            <a href="#">
                                <img src={partner4}
                                    alt="Partner Image"/>
                            </a>
                        </div>
                    </div>
                    <div className="single-partner-item">
                        <div className="partner-img">
                            <a href="#">
                                <img src={partner5}
                                    alt="Partner Image"/>
                            </a>
                        </div>
                    </div>
                    <div className="single-partner-item">
                        <div className="partner-img">
                            <a href="#">
                                <img src={partner6}
                                    alt="Partner Image"/>
                            </a>
                        </div>
                    </div>
                    <div className="single-partner-item">
                        <div className="partner-img">
                            <a href="#">
                                <img src={partner4}
                                    alt="Partner Image"/>
                            </a>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
    );
};
export default Partners;
