
import PageBanner from "../components/PageBanner";
import Partners from "../components/Partners";
import { Link } from "react-router-dom";
import Layout from "../layout/Layout";
import {element2,element3,element4,gallery404} from "../assets/images"


const E404 = () => {
  return (
    <Layout>
      <PageBanner pageName={"404 Pages"} title="404 Pages" />{" "}
      <section className="error-page-area pt-130 pb-130 p-r z-1">
        <div className="object object-one">
          <span>
            <img src={element2} alt="" />
          </span>
        </div>
        <div className="object object-two">
          <span>
            <img src={element3} alt="" />
          </span>
        </div>
        <div className="object object-three">
          <span>
            <img src={element4} alt="" />
          </span>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="error-content text-center wow fadeInUp">
                <img
                  src={gallery404}
                  className="mb-110"
                  alt="404 Image"
                />
                <h2>OPPS! This Page are Can’t Be Found</h2>
                <p>
                  Sit amet consectetur adipiscing elit. Et id purus sem morbi.
                  Integer a sollicitudin ac eget sed consectetur et nam.
                  Elementum eu egestas faucibus bibendum aliquetesy ullamcorper
                  quis aliquet lorem. Mor phasellus dictum tellus
                </p>
                <Link legacyBehavior href="/">
                  <a className="main-btn btn-red">
                    go to home
                    <i className="far fa-arrow-right" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Partners />
    </Layout>
  );
};
export default E404;
