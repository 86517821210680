import logo from './logo.svg';
import { Route,HashRouter, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Index from './pages';
import About from './pages/AboutPage';
import Menu from './pages/menu';
import Products from './pages/products';
import ProductsDetails from './pages/product-details';
import BlogStandard from './pages/blog-standard';
import BlogDetails from './pages/blog-details';
import Chefs from './pages/chefs';
import History from './pages/history';
import E404 from './pages/404';
import Reservations from './pages/reservations';
import FAQ from './pages/FaqPage';
import Contact from './pages/ContactPage';
import  {MenuProvider}  from './contexts/MenuContext';
function App() {
  return (
      <MenuProvider>
    <div className="App">
  <HashRouter>
            <Routes>
              <Route path='/' exact element={<Index />}/>
              <Route path='/about' exact element={<About/>} />
              <Route path='/menu' exact element={<Menu/>} />
              <Route path='/products' exact element={<Products/>} />
              <Route path='/product-details' exact element={<ProductsDetails/>} />
              <Route path='/blog-standard' exact element={<BlogStandard/>} />
              <Route path='/blog-details' exact element={<BlogDetails/>} />
              <Route path='/chefs' exact element={<Chefs/>} />
              <Route path='/history' exact element={<History/>} />
              <Route path='/reservations' exact element={<Reservations/>} />
              <Route path='/404' exact element={<E404/>} />
              <Route path='/faq' exact element={<FAQ/>} />
              <Route path='/contact' exact element={<Contact/>} />
            </Routes>
        </HashRouter>
    </div>
        </MenuProvider>
  );
}

export default App;
